<template>
  <b-nav-item-dropdown
    class="dropdown-cart mr-25"
    menu-class="dropdown-menu-media"
    right
    :disabled="!dataItem.items.length"
    @show="fetchItems"
  >
    <template #button-content>
      <feather-icon
        :badge="dataItem.items.length? Number(dataItem.items.length) : null"
        class="text-body"
        icon="ShoppingCartIcon"
        size="21"
      />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          {{ $t('Teklif Listem') }}
        </h4>
        <b-badge
          v-if="dataItem.items.length"
          pill
          variant="light-primary"
        >
          {{ dataItem.items.length? Number(dataItem.items.length) + ' ' + $t('Ürün') : null }}
        </b-badge>
      </div>
    </li>

    <!-- Cart Items -->
    <vue-perfect-scrollbar
      v-if="dataItem.items.length"
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
    >
      <b-media
        v-for="item in dataItem.items"
        :key="item.name"
      >
        <template #aside>
          <b-img
            :src="productMediaURL + item.image"
            :alt="item.product"
            rounded
            width="62px"
          />
        </template>
        <feather-icon
          icon="XIcon"
          class="cart-item-remove cursor-pointer"
          @click.stop="removeItemFromCart(item.id)"
        />
        <div class="media-heading w-100">
          <h6 class="cart-item-title text-primary font-weight-bolder mb-0 text-nowrap">
            {{ item.product }}
          </h6>
          <small class="cart-item-by">{{ item.product_quality }}</small>
        </div>
      </b-media>
    </vue-perfect-scrollbar>

    <!-- Cart Footer -->
    <li
      v-if="dataItem.items.length"
      class="dropdown-menu-footer"
    >
      <b-button
        variant="success"
        block
        :to="{ name: 'OfferRequest' }"
      >
        <FeatherIcon icon="SendIcon" />
        {{ $t('Teklif İste') }}
      </b-button>
    </li>
    <p
      v-if="!dataItem.items.length"
      class="m-0 p-1 text-center"
    >
      {{ $t('Listeniz boş') }}
    </p>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BBadge, BMedia, BImg, BButton,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BImg,
    VuePerfectScrollbar,
    BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      productMediaURL: this.$store.state.app.productMediaURL,
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
      },
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['baskets/dataItem']
    },
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.$store.dispatch('baskets/getData')
    },
    fetchItems() {
      this.$store.dispatch('baskets/getData')
    },
    removeItemFromCart(productId) {
      this.$store.dispatch('baskets/removeItem', {
        id: productId,
      })
        .then(response => {
          if (response) {
            this.getData()
          }
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.dropdown-cart {
  .media {
    .media-aside {
      align-items: center;
    }
  }
}
</style>
