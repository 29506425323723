export default [
  {
    title: 'Dashboard',
    route: 'home',
    icon: 'HomeIcon',
    resource: 'DASHBOARD',
    action: 'read',
  },
  {
    title: 'Kataloglarım',
    route: 'Catalogs',
    icon: 'BookIcon',
    resource: 'CLIENT_CATALOGS',
    action: 'read',
  },
  {
    title: 'Tekliflerim',
    route: 'Offers',
    icon: 'FileTextIcon',
    resource: 'CLIENT_OFFERS',
    action: 'read',
  },
  {
    title: 'Mesajlarım',
    route: 'Messages',
    icon: 'MessageSquareIcon',
    resource: 'CLIENT_MESSAGES',
    action: 'read',
  },
  {
    title: 'Hesabım',
    route: 'Account',
    icon: 'SettingsIcon',
    resource: 'CLIENT_ACCOUNT',
    action: 'read',
  },
]
